import React from 'react'
import ReactDOM from 'react-dom/client'
import {  RouterProvider } from 'react-router-dom'
// import { Toaster } from '@/components/ui/toaster'
// import {Toaster as HotToaster} from 'react-hot-toast'
import router from '@/router'
import '@/index.css'
import Providers from './components/providers'
import { Toaster } from './components/ui/sonner'
// import NextTopLoader from 'nextjs-toploader';
import { ImageLoader } from './components/loader'
// import TopLoader from './components/toploader'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Providers>
      {/* <RouterProvider router={router} fallbackElement={<NextTopLoader />} /> */}
      <RouterProvider router={router} fallbackElement={<ImageLoader />} />
      {/* <Toaster /> */}

      {/* <TopLoader /> */}

      <Toaster richColors position='top-center' />
      {/* <HotToaster /> */}
    </Providers>
  </React.StrictMode>
)
