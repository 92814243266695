import { store } from '@/lib/redux/store'
import { ReactNode } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from './theme-provider'

const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <Provider store={store}>
      <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
        {children}
      </ThemeProvider>
    </Provider>
  )
}

export default Providers
