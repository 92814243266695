// import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import GeneralError from './pages/errors/general-error'
import NotFoundError from './pages/errors/not-found-error'
import MaintenanceError from './pages/errors/maintenance-error'
import { lazy } from 'react'

// const Index = lazy(() => import('../pages/Index'));

// const SignIn = lazy(() => import('./pages/auth/sign-in'))
const AppShell = lazy(() => import('./components/app-shell'))
const DashboardV2 = lazy(() => import('./pages/dashboardv2'))
const Dashboard = lazy(() => import('./pages/dashboard'))
const About = lazy(() => import('./pages/about'))
const Projects = lazy(() => import('./pages/projects'))
const Services = lazy(() => import('./pages/services'))
const SocialHandles = lazy(() => import('./pages/social-handles'))
const Testimonials = lazy(() => import('./pages/testimonials'))
const Timeline = lazy(() => import('./pages/timeline'))
const Youtube = lazy(() => import('./pages/youtube'))
const Skills = lazy(() => import('./pages/skills'))

const router = createBrowserRouter([
  // Auth routes
  {
    path: '/sign-in',
    lazy: async () => ({
      Component: (await import('./pages/auth/sign-in')).default,
    }),
  },
  // {
  //   path: '/sign-in-2',
  //   lazy: async () => ({
  //     Component: (await import('./pages/auth/sign-in-2')).default,
  //   }),
  // },
  // {
  //   path: '/sign-up',
  //   lazy: async () => ({
  //     Component: (await import('./pages/auth/sign-up')).default,
  //   }),
  // },
  {
    path: '/forgot-password',
    lazy: async () => ({
      Component: (await import('./pages/auth/forgot-password')).default,
    }),
  },
  {
    path: '/reset-password',
    lazy: async () => ({
      Component: (await import('./pages/auth/reset-password')).default,
    }),
  },

  // Main routes
  {
    path: '/',
    // lazy: async () => {
    //   const AppShell = await import('./components/app-shell')
    //   return { Component: AppShell.default }
    // },
    element: <AppShell />,
    errorElement: <GeneralError />,
    children: [
      {
        index: true,
        element: <DashboardV2 />,
        // lazy: async () => ({
        //   Component: (await import('./pages/dashboardv2')).default,
        // }),
      },
      {
        path: '/dashboard',
        element: <Dashboard />,

        // lazy: async () => ({
        //   Component: (await import('./pages/dashboard')).default,
        // }),
      },
      {
        path: '/about',
        element:<About/>
        // lazy: async () => ({
        //   Component: (await import('./pages/about')).default,
        // }),
      },
      {
        path: '/projects',
        element:<Projects/>
        // lazy: async () => ({
        //   Component: (await import('./pages/projects')).default,
        // }),
      },
      {
        path: '/services',
        element:<Services/>
        // lazy: async () => ({
        //   Component: (await import('./pages/services')).default,
        // }),
      },
      {
        path: '/social-handles',
        element:<SocialHandles/>
        // lazy: async () => ({
        //   Component: (await import('./pages/social-handles')).default,
        // }),
      },
      {
        path: '/testimonials',
        element:<Testimonials/>
        // lazy: async () => ({
        //   Component: (await import('./pages/testimonials')).default,
        // }),
      },
      {
        path: '/youtube',
        element:<Youtube/>
        // lazy: async () => ({
        //   Component: (await import('./pages/youtube')).default,
        // }),
      },
      {
        path: '/timeline',
        element:<Timeline/>
        // lazy: async () => ({
        //   Component: (await import('./pages/timeline')).default,
        // }),
      },
      {
        path: '/skills',
        element:<Skills/>
        // lazy: async () => ({
        //   Component: (await import('./pages/skills')).default,
        // }),
      },
      {
        path: 'tasks',
        lazy: async () => ({
          Component: (await import('./pages/tasks')).default,
        }),
      },

      {
        path: 'chats',
        lazy: async () => ({
          Component: (await import('@/components/coming-soon')).default,
        }),
      },
      {
        path: 'apps',
        lazy: async () => ({
          Component: (await import('./pages/app')).default,
        }),
      },
      {
        path: 'users',
        lazy: async () => ({
          Component: (await import('@/components/coming-soon')).default,
        }),
      },
      {
        path: 'analysis',
        lazy: async () => ({
          Component: (await import('@/components/coming-soon')).default,
        }),
      },
      {
        path: 'extra-components',
        lazy: async () => ({
          Component: (await import('@/pages/extra-components')).default,
        }),
      },
      {
        path: 'settings',
        lazy: async () => ({
          Component: (await import('./pages/settings')).default,
        }),
        errorElement: <GeneralError />,
        children: [
          {
            index: true,
            lazy: async () => ({
              Component: (await import('./pages/settings/profile')).default,
            }),
          },
          {
            path: 'account',
            lazy: async () => ({
              Component: (await import('./pages/settings/account')).default,
            }),
          },
          {
            path: 'appearance',
            lazy: async () => ({
              Component: (await import('./pages/settings/appearance')).default,
            }),
          },
          {
            path: 'notifications',
            lazy: async () => ({
              Component: (await import('./pages/settings/notifications'))
                .default,
            }),
          },
          {
            path: 'display',
            lazy: async () => ({
              Component: (await import('./pages/settings/display')).default,
            }),
          },
          {
            path: 'error-example',
            lazy: async () => ({
              Component: (await import('./pages/settings/error-example'))
                .default,
            }),
            errorElement: <GeneralError className='h-[50svh]' minimal />,
          },
        ],
      },
    ],
  },

  // Error routes
  { path: '/500', Component: GeneralError },
  { path: '/404', Component: NotFoundError },
  { path: '/503', Component: MaintenanceError },

  // Fallback 404 route
  { path: '*', Component: NotFoundError },
])

export default router
