import { IconLoader } from '@tabler/icons-react'

export default function Loader() {
  return (
    <div className='flex h-svh w-full items-center justify-center'>
      <IconLoader className='animate-spin' size={32} />
      <span className='sr-only'>loading</span>
    </div>
  )
}

export const ImageLoader = () => {
  return (
    <div className='flex min-h-screen w-full min-w-full animate-pulse items-center justify-center bg-slate-50 dark:bg-slate-950'>
      <img
        className='flex h-[150px] w-[150px] animate-pulse items-center justify-center rounded-full'
        src='https://portfolio-image-store.s3.amazonaws.com/WEBSITE-IMAGES+/1708868532478-10.png'
        alt=''
        loading='lazy'
      />
    </div>
  )
}
