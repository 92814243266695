import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  About,
  Project,
  Service,
  Skill,
  SocialHandle,
  Testimonial,
  Timeline,
  User,
  Youtube,
} from '../interface'
import { CardType } from '@/components/show-on-site-btn'

interface InitialState {
  user: User | null
  about: About | null
  projects: Project[]
  services: Service[]
  socialHandle: SocialHandle[]
  testimonials: Testimonial[]
  timeline: Timeline[]
  skills: Skill[]
  youtube: Youtube[]
  category: string
}

const initialState: InitialState = {
  user: null,
  about: null,
  projects: [],
  services: [],
  socialHandle: [],
  testimonials: [],
  timeline: [],
  skills: [],
  youtube: [],
  category: '',
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // set all the state
    setUser: (state, action: PayloadAction<User>) => {
      const user = action.payload

      const sortedFilteredProjects = user.projects.sort(
        (a, b) => a.sequence - b.sequence
      )
      const sortedSkills = user.skills.sort((a, b) => a.sequence - b.sequence)

      state.user = user
      state.projects = sortedFilteredProjects
      state.services = user.services
      state.socialHandle = user.social_handles
      state.testimonials = user.testimonials
      state.timeline = user.timeline
      state.skills = sortedSkills
      state.about = user.about
      state.youtube = user.youtube
    },

    updateCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload
    },

    updateProject: (state, action: PayloadAction<Project>) => {
      const updatedProject = action.payload
      const index = state.projects.findIndex(
        (project) => project._id === updatedProject._id
      )

      if (index !== -1) {
        state.projects[index] = updatedProject
      } else {
        state.projects.unshift(action.payload)
      }
    },

    deleteProject: (state, action: PayloadAction<Project>) => {
      const deleteProject = action.payload
      state.projects = state.projects.filter(
        (project) => project._id !== deleteProject._id
      )
    },
    updateService: (state, action: PayloadAction<Service>) => {
      const updatedService = action.payload
      const index = state.services.findIndex(
        (service) => service._id === updatedService._id
      )

      if (index !== -1) {
        state.services[index] = updatedService
      } else {
        state.services.unshift(action.payload)
      }
    },

    deleteService: (state, action: PayloadAction<Service>) => {
      const deleteService = action.payload
      state.services = state.services.filter(
        (service) => service._id !== deleteService._id
      )
    },

    updateTestimonial: (state, action: PayloadAction<Testimonial>) => {
      const updatedTestimonial = action.payload
      const index = state.testimonials.findIndex(
        (testimonial) => testimonial._id === updatedTestimonial._id
      )

      if (index !== -1) {
        state.testimonials[index] = updatedTestimonial
      } else {
        state.testimonials.unshift(action.payload)
      }
    },

    deleteTestimonial: (state, action: PayloadAction<Testimonial>) => {
      const deleteTestimonial = action.payload
      state.testimonials = state.testimonials.filter(
        (testimonial) => testimonial._id !== deleteTestimonial._id
      )
    },

    updateSocialHandle: (state, action: PayloadAction<SocialHandle>) => {
      const updatedSocialHandle = action.payload
      const index = state.socialHandle.findIndex(
        (social) => social._id === updatedSocialHandle._id
      )

      if (index !== -1) {
        state.socialHandle[index] = updatedSocialHandle
      } else {
        state.socialHandle.unshift(updatedSocialHandle)
      }
    },

    deleteSocialHandle: (state, action: PayloadAction<SocialHandle>) => {
      const deleteSocialHandle = action.payload
      state.socialHandle = state.socialHandle.filter(
        (social) => social._id !== deleteSocialHandle._id
      )
    },

    updateYoutube: (state, action: PayloadAction<Youtube>) => {
      const updatedYoutube = action.payload
      const index = state.youtube.findIndex(
        (youtube) => youtube._id === updatedYoutube._id
      )

      if (index !== -1) {
        state.youtube[index] = updatedYoutube
      } else {
        state.youtube.unshift(updatedYoutube)
      }
    },

    deleteYoutube: (state, action: PayloadAction<Youtube>) => {
      const deleteYoutube = action.payload
      state.youtube = state.youtube.filter(
        (youtube) => youtube._id !== deleteYoutube._id
      )
    },

    updateSkill: (state, action: PayloadAction<Skill>) => {
      const updatedSkill = action.payload
      // console.log(action.payload)
      const index = state.skills.findIndex(
        (skill) => skill._id === updatedSkill._id
      )

      // console.log(index)

      if (index !== -1) {
        state.skills[index] = updatedSkill
      } else {
        state.skills.unshift(updatedSkill)
      }
    },

    deleteSkill: (state, action: PayloadAction<Skill>) => {
      const deleteSkill = action.payload
      state.skills = state.skills.filter(
        (skill) => skill._id !== deleteSkill._id
      )
    },

    updateTimeline: (state, action: PayloadAction<Timeline>) => {
      const updatedTimeline = action.payload
      const index = state.timeline.findIndex(
        (line) => line._id === updatedTimeline._id
      )

      if (index !== -1) {
        state.timeline[index] = updatedTimeline
      } else {
        state.timeline.unshift(updatedTimeline)
      }
    },

    deleteTimeline: (state, action: PayloadAction<Timeline>) => {
      const deleteTimeline = action.payload
      state.timeline = state.timeline.filter(
        (line) => line._id !== deleteTimeline._id
      )
    },

    updatePersonalDetails: (state, action: PayloadAction<About>) => {
      state.about = action.payload
    },

    deleteItems: (state, action: PayloadAction<string[]>) => {
      const itemIds = action.payload
      state.projects = state.projects.filter(
        ({ _id }) => !itemIds.includes(_id)
      )
    },

    disableItems: (
      state,
      action: PayloadAction<{ id: string; type: CardType }>
    ) => {
      const { type, id } = action.payload

      const toggleEnabled = <T extends { _id: string; enabled: boolean }>(
        items: T[]
      ): T[] => {
        return items.map((item) => {
          if (item._id === id) {
            return { ...item, enabled: !item.enabled }
          }
          return item
        })
      }

      switch (type) {
        case 'projects':
          state.projects = toggleEnabled(state.projects)
          break
        case 'services':
          state.services = toggleEnabled(state.services)
          break
        case 'skills':
          state.skills = toggleEnabled(state.skills)
          break
        case 'social_handles':
          state.socialHandle = toggleEnabled(state.socialHandle)
          break
        case 'testimonials':
          state.testimonials = toggleEnabled(state.testimonials)
          break
        case 'timeline':
          state.timeline = toggleEnabled(state.timeline)
          break
        case 'youtube':
          state.youtube = toggleEnabled(state.youtube)
          break
        default:
          break
      }
    },

    resetStore: () => initialState,
  },
})

export const {
  setUser,
  updateProject,
  updateService,
  updateTestimonial,
  updateSocialHandle,
  updateSkill,
  updateTimeline,
  resetStore,
  updatePersonalDetails,
  deleteItems,
  disableItems,
  updateYoutube,
  updateCategory,

  deleteService,
  deleteProject,
  deleteSkill,
  deleteSocialHandle,
  deleteTestimonial,
  deleteTimeline,
  deleteYoutube,
} = userSlice.actions
export default userSlice.reducer
